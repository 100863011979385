import React from "react";
import PropTypes from "prop-types";
import "./profile-item.scss";
import ItemWithShadow from "../item-with-shadow/item-with-shadow";

const ProfileItem = ({ person: { name, role, profileImage }, dark }) => {
  return (
    <ItemWithShadow dark={dark}>
      <div className="profile-item-wrapper">
        <div className="profile-item-name">{name}</div>
        <div className="profile-item-role">{role}</div>
        <div className="profile-item-profile-image-wrapper">
          <img
            className="profile-item-profile-image"
            src={profileImage}
            alt="profile"
          />
        </div>
      </div>
    </ItemWithShadow>
  );
};

ProfileItem.propTypes = {
  persom: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    profileImage: PropTypes.string,
  }),
  dark: PropTypes.bool, // shade, light by default
};

ProfileItem.defaultProps = {
  dark: false,
};

export default ProfileItem;
