import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import "./who-we-are.scss";

import Button from "../components/button/button";
import SectionHeading from "../components/section-heading/section-heading";
import ContactSection from "../components/contact-section/contact-section";
import CappedWidth from "../components/capped-width/capped-width";

import ProfileItem from "../components/profile-item/profile-item";
import ItemWithShadow from "../components/item-with-shadow/item-with-shadow";

//Import images from image.js here:
// import { EllenWreath } from "../components/image"

// Our Staff Images
import EricHarrisBraun from "../images/eric-harris-braun.jpg";
import JonParadise from "../images/jon-paradise.jpg";
import JaimeMoyer from "../images/jaime-moyer.jpg";
import EllenWreath from "../images/ellen-wreath.png";

// Our Resident Editors Images
import LeahBobet from "../images/leah-bobet.jpg";
import JeanneCavelos from "../images/jeanne-cavelos.jpg";
import JudithTarr from "../images/judith-tarr.jpg";

const WhoWeArePage = () => (
  <Layout>
    <SEO title="Who We Are" />

    <div className="section last who-we-are">
      <CappedWidth>
        <h1 className="page-title">Who We are</h1>
        <p>
          We’re a very small company—originally a mom-and-pop-and-friend
          Internet start-up—dedicated to providing this workshop to aspiring
          writers. We do other things as well, and hire freelancers to help out.
          We are not a big faceless company out to milk your wallet for all we
          can get. Just to prove it, here are our faces.
        </p>
      </CappedWidth>
      <div className="our-staff-wrapper">
        <SectionHeading>Our Staff</SectionHeading>

        <div className="profile-odd-row">
          <ProfileItem
            person={{
              name: "Eric Harris-Braun",
              role: "Owner/code monkey",
              profileImage: EricHarrisBraun,
            }}
          />
          <ProfileItem
            person={{
              name: "Jon Paradise",
              role: "Member support staff",
              profileImage: JonParadise,
            }}
          />
          <ProfileItem
            person={{
              name: "Jaime Lee Moyer",
              role: "Newsletter editor",
              profileImage: JaimeMoyer,
            }}
          />
        </div>
        <div className="profile-odd-row">
          <ItemWithShadow>
            In Memoriam of <br />
            <a
              href="https://ellen.harris-braun.com/blog/"
              target="_blank"
              rel="noopener noreferrer"
              className="profile-item-name ellen"
            >
              Ellen Harris-Braun
            </a>
            <div>
              <img
                className="profile-item-image-ellen"
                src={EllenWreath}
                alt="Ellen Harris-Braun"
              />
            </div>{" "}
          </ItemWithShadow>
        </div>
      </div>
    </div>

    {/* Our Resident Editors */}
    <div className="resident-editors" id="resident-editors">
      <div className="section last">
        <CappedWidth>
          <SectionHeading>Our Resident Editors</SectionHeading>
          {/* Change or add current resident editors here: */}
          {/* For odd number of current resident editors, use the structure below */}
          <div className="profile-items-wrapper">
            <div className="profile-odd-row">
              <ProfileItem
                person={{
                  name: "Leah Bobet",
                  role: "Resident Editor",
                  profileImage: LeahBobet,
                }}
              />
              <ProfileItem
                person={{
                  name: "Jeanne Cavelos",
                  role: "Resident Editor",
                  profileImage: JeanneCavelos,
                }}
              />
              <ProfileItem
                person={{
                  name: "Judith Tarr",
                  role: "Resident Editor",
                  profileImage: JudithTarr,
                }}
              />
            </div>
          </div>
          {/* If even number of current resident editors, use the commented out structure */}
          {/* <div className="profile-items-wrapper">
            <div className="profile-items-row">
              <ProfileItem
                person={{
                  name: "Leah Bobet",
                  role: "Resident Editor",
                  profileImage: LeahBobet,
                }}
              />
              <ProfileItem
                person={{
                  name: "Jeanne Cavelos",
                  role: "Resident Editor",
                  profileImage: JeanneCavelos,
                }}
              />
            </div>
            <div className="profile-items-row">
              <ProfileItem
                person={{
                  name: "Gemma Files",
                  role: "Resident Editor",
                  profileImage: GemmaFiles,
                }}
              />
              <ProfileItem
                person={{
                  name: "Judith Tarr",
                  role: "Resident Editor",
                  profileImage: JudithTarr,
                }}
              />
            </div>
          </div> */}

          <div className="about-resident-editors-button">
            <Button
              color="purple"
              semiTransparentFill
              text="About Resident Editors"
              path="/residenteditors.shtml"
            />
          </div>
        </CappedWidth>
        {/* Change or add previous resident editors list here: */}
        <div className="previous-residents-wrapper">
          <CappedWidth>
            <h3>Previous Resident Editors</h3>
            <p>
              Gemma Files, Elizabeth Bear, Liz Bourke, Amal El-Mohtar, C.C.
              Finlay, Jenni Smith-Gaynor, Susan Marie Groppi, Nalo Hopkinson,
              James Patrick Kelly, John Klima, Kelly Link, Karin Lowachee, Karen
              Meisner, and Paul Witcover.
            </p>
          </CappedWidth>
        </div>
      </div>
    </div>

    <ContactSection />
  </Layout>
);

export default WhoWeArePage;
